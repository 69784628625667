
// import './index.scss'
//import SplitType from 'split-type'

export default class {
  constructor (el,device) {


    this.DOM = {
      el:el,
      t:el.querySelector('.cnt_t'),
      l:el.querySelector('.cnt_l'),
      r:el.querySelector('.cnt_r'),
      main:el.querySelector('.cnt_mn')
    }
    this.device = device
    this.active = 0

    this.create()
    
  }

  async create(){
    


  
  }
  
  async start(){
    let lpt = '--cornerleft'
    let rpt = '--cornerright'

    


    gsap.to(this.DOM.el,{opacity:1,onComplete:()=>{
      this.DOM.t.classList.add('act')
    }})

    let delay = 1.2

    gsap.set(this.DOM.l.querySelector('.cnt_md'),{[rpt+'0']:100+'%',[rpt+'1']:100+'%'})
    gsap.fromTo(this.DOM.l.querySelector('.cnt_l_t'),{opacity:0},{opacity:1,delay:delay+.8})
    gsap.fromTo(this.DOM.l.querySelector('.cnt_md'),{[lpt+'0']:100+'%',[lpt+'1']:100+'%'},{[lpt+'0']:0+'%',[lpt+'1']:0+'%',duration:1,delay:delay,ease:'power4.inOut'})
    gsap.fromTo(this.DOM.l.querySelector('.cnt_md_o'),{xPercent:22},{xPercent:0,duration:1.2,delay:delay,ease:'power2.inOut'})

    gsap.set(this.DOM.r.querySelector('.cnt_md'),{[lpt+'0']:0+'%',[lpt+'1']:0+'%'})
    gsap.fromTo(this.DOM.r.querySelector('.cnt_r_t'),{opacity:0},{opacity:1,delay:delay+.8})
    gsap.fromTo(this.DOM.r.querySelector('.cnt_md'),{[rpt+'0']:0+'%',[rpt+'1']:0+'%'},{[rpt+'0']:100+'%',[rpt+'1']:100+'%',duration:1,delay:delay,ease:'power4.inOut'})
    await gsap.fromTo(this.DOM.r.querySelector('.cnt_md_o'),{xPercent:-22},{xPercent:0,duration:1.2,delay:delay,ease:'power2.inOut'})

    
    this.DOM.l.classList.add('act')
    this.DOM.r.classList.add('act')


    
  }
  initEvents(){
    this.DOM.l.onmouseenter = (ev)=>{
      this.DOM.main.classList.remove('op2')
      this.DOM.main.classList.add('op1')

    }
    this.DOM.r.onmouseenter = (ev)=>{
      this.DOM.main.classList.remove('op1')
      this.DOM.main.classList.add('op2')
    }
    this.DOM.l.onmouseleave = (ev)=>{
      this.DOM.main.classList.remove('op1')

    }
    this.DOM.r.onmouseleave = (ev)=>{
      this.DOM.main.classList.remove('op2')
    }
  }

  removeEvents(){
    
  }

  onResize(){
    this.w = window.innerWidth * .5
  }
}
