
// import './index.scss'
//import SplitType from 'split-type'

export default class {
  constructor (el,device) {

    this.DOM = {
      el:el,
      imgs: el.querySelectorAll('.media'),
      links: el.querySelectorAll('.link'),
    }
    this.device = device
    this.active = 0
    this.lastIndex = 0

    this.create()
    
  }

  async create(){
    
    
  
  }
  
  async start(){
    gsap.fromTo(this.DOM.el, {opacity:0},{duration:1, opacity:1,delay:.2,ease:'power2.inOut'})
    gsap.fromTo(this.DOM.el, {lineHeight:2},{duration:2,lineHeight:1.2,ease:'power1.inOut'})
  }

  loadImg(i){
    if(this.DOM.imgs[i].getAttribute('src')){
      return -1
    }


    let img = new Image()
    let url = ''
    if(this.DOM.imgs[i].dataset.lazy){
        
      url = this.DOM.imgs[i].dataset.lazy
    }
    
    let gif=0
    if(/\.(gif)$/.test(url)){
      gif=1
    }


    this.DOM.imgs[i].onload = () => {
      delete this.DOM.imgs[i].dataset.lazy
      this.DOM.imgs[i].classList.add('Ldd')
    
    }
    img.onload = () =>{
      this.DOM.imgs[i].src = url
    }
    img.src = url

    if(gif==1){
      this.DOM.imgs[i].src = url
      this.DOM.imgs[i].classList.add('Ldd')
    }
  }

  initEvents(){
    for(let [i, l] of this.DOM.links.entries()){
      l.addEventListener('mouseenter', () => {
        // console.log(this.DOM.imgs[this.lastIndex])
        this.DOM.imgs[this.lastIndex].classList.remove('act')
        this.lastIndex = i
        this.loadImg(this.lastIndex)
        this.DOM.imgs[this.lastIndex].classList.add('act')
      })
      
    }
    
  }

  removeEvents(){
    
  }

  onResize(){
    this.w = window.innerWidth * .5
  }
}
