
// import './index.scss'
//import SplitType from 'split-type'

export default class {
  constructor (el,device) {


    this.DOM = {
      el:el,
    }
    this.device = device
    this.active = 0

    this.create()
    
  }

  async create(){
    


  
  }
  
  async start(){
   

    gsap.to(this.DOM.el,{opacity:1,duration:.6,ease:'power2.inOut'})


  }
  initEvents(){
    
  }

  removeEvents(){
    
  }

  onResize(){
  }
}
