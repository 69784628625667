

export function addPop () {

  window.addEventListener('popstate',(ev)=> this.onPopState(ev), { passive: true })
  
}



  // EVENTS

  // Patrás y palante
export async function onPopState (ev)  {
  ev.preventDefault()
  
  let check = null
  if(
    this.template == 'projects'
    &&
    (window.location.pathname.includes('proyecto')
    ||
    window.location.pathname.includes('proyectos')
    ||
    window.location.pathname.includes('projects')  
    ||
    window.location.pathname.includes('project'))

    ){
      if(this.page.isPop == 1){
        return false
      }
      if(this.main.device < 2){
        this.page.isPop = 1
        check = this.page.searchUrl(window.location.href)
        window.history.pushState({title:check.querySelector('.proj_t').innerHTML},check.querySelector('.proj_t').innerHTML, check.href.replace(window.location.origin, ''))
      
        
        await this.timeout(1640)
        this.page.isPop = 0
      }
    }
    else{
      this.onChange({
        url: window.location.pathname,
        link:null,
        push: false
      })
    }



    
}



  // Empieza el cambio
export async function onChange ({ url = null, link = null, id = null, title = null }) {
    // url = url.replace(window.location.origin, '')
    if (this.isLoading) return



    if(
      this.template == 'projects'
      && 
      (
      url.includes('proyecto')
      ||
      url.includes('proyectos')
      ||
      url.includes('projects')  
      ||
      url.includes('project')
      )
    ){
      
      
      if(this.main.device > 1){
        // let check = await this.page.searchUrl(url)
        // await this.timeout(1640)
        // this.page.isPop = 0
      }
      else{

        let check = await this.page.searchUrl(url)
      
        if(import.meta.env.DEV == false){
          window.history.replaceState({title:check.querySelector('.proj_t').innerHTML},check.querySelector('.proj_t').innerHTML, url.replace(window.location.origin, ''))
        }

        await this.timeout(1640)
        this.page.isPop = 0
      }

      return false
      

    } 
    url = url.replace(window.location.origin, '')
    if (this.url === url) return

    this.lenis.stop()
    this.issame = 0
    this.page.isVisible = false
    this.isLoading = true
    let time = 450
    
    this.url = url

    let functowait = []
    // if(this.nav.isOpen==1){
    //   this.nav.closeMenu()
    //   this.nav.isOpen=0
    //   time = 12
    // }
    // functowait.push()
    // await this.page.hide()
    
    document.body.style.pointerEvents = 'none'

    const request = await window.fetch(url, {
      headers: {
        'X-Requested-With': 'XMLHttpRequest'
      }
    })

    const response = await request.text()
    var push = true


    if(this.canvas){
      this.canvas.cleanTemp()
    }
    await this.page.animOut()
    // this.loader.showPop()

	  Promise.all([
      this.timeout(time),
      this.onRequest({
        push,
        response,
        url,
        id
      })
    ]).then(()=>{
      this.newView()

    })

  }

  // llamada de la nueva URL
export async function onRequest ({ push, response, url, id }) {
    const html = document.createElement('div')

    html.innerHTML = response
    if(html.querySelector('title')){
      document.title = html.querySelector('title').textContent
    }
    this.content = html.querySelector('#content')
    

    if (push) {
      window.history.pushState({}, document.title, url)
    }

    await this.page.hide()
    this.lenis.scrollTo(0,{immediate:true,lock:true,force:true})
    this.page.DOM.el.remove()
    
    

    this.template = this.content.dataset.template
    this.newpage = this.pages.get(this.template)
    this.newpage.id = this.content.dataset.id

    this.newpage.ispop = 1
    await this.newpage.create(this.content,this.main)
    this.nav.menuState(this.content.dataset.menu)
    if(this.canvas){
      await this.canvas.createTemp(this.template)
    }
    
}


export async function newView(){
    
    
     
    // console.log('estoy aquí')
    // this.loader.hide()

    if(this.mouse){
      this.mouse.reset()
    }

    
    document.body.style.pointerEvents = ''
    this.isLoading = false
    
    this.loader.hidePop()
    this.newpage.show(0)

    if(this.canvas){
      this.canvas.show()
    }

    this.page = this.newpage
    let state = this.page.start(0)

    this.newpage.ispop = 0
    


    this.addControllers()
    this.lenis.start()

}


//CHECKS 
//1.Onchange
//2.Pops palante y patrás
////3.tener en cuenta menú abierto o modales
////4.tener en cuenta transiciones entre vistas del mismo tipo ( de post a post, por ejemplo )




export  function resetLinks(){
  const links = document.querySelectorAll('a')
  

  const actual = window.location.href
  
  for(let link of links){
    //CLEAN CLASS
    if(actual==link.href){
      link.classList.add('actLink')

    }
    else{
      link.classList.remove('actLink')
      
    }

    if(link.classList.contains('Awrite')){

      // link.onmouseenter = () => this.writeFn(link)


      // link.onmouseenter = () => {
      //   this.main.events.animglobal.detail.el = link
      //   document.dispatchEvent(this.main.events.animglobal)
       
      // }

      
    }

    let isLocal = link.href.indexOf(this.main.base) == 0
    const isAnchor = link.href.indexOf('#') > -1
    if(link.classList.contains('noAjax')){
      continue
    }
    if(link.classList.contains('isFile')){
      link.rel = 'noopener'
      link.target = '_blank'
      continue
    }

    if(link.dataset.type && !isAnchor){
      if(import.meta.env.DEV){
        isLocal = true
        if(link.dataset.type && link.dataset.type != 'project'){
          link.href = '/'+link.dataset.type+'.html'
        }
      }
      link.removeAttribute('data-type')
    }

   
    


    if (isLocal || isAnchor) {
      link.onclick = async(event) => {
        event.preventDefault()
        
        if (!isAnchor) {
          
          this.onChange({
            url: link.href,
            id: link.dataset.id,
            link:link
            
          })
        }
        else{
          
          if(this.nav.isOpen==1){
            this.nav.isOpen = 0
            this.nav.closeMenu()
            await this.timeout(450)



          }
          if(link.href.split('#').length == 2){
            this.lenis.scrollTo('#'+link.href.split('#')[1],{offset:-100})
          }

        }
      }
    } else if (link.href.indexOf('mailto') === -1 && link.href.indexOf('tel') === -1) {
      link.rel = 'noopener'
      link.target = '_blank'
    }
    
  }
}
