import Page from '/js🧠🧠🧠/page👁️/pagemain.js'

//COMPS
import Intro from './0Intro'



class Home extends Page {
  constructor (main) {
    super(main)


    this.colors = {
      dark:'#FAF9F7',
      light:'#231616',
      gray:'#615353',
    }
  }

  async create(content,main,temp=undefined) {
    super.create(content,main)
    if(temp!=undefined){
      
      document.querySelector('#content').insertAdjacentHTML('afterbegin',temp)
    }
    else{

      let data = await this.loadRestApi(this.main.base+'/wp-json/wp/v2/pages/',content.dataset.id,content.dataset.template)
      document.querySelector('#content').insertAdjacentHTML('afterbegin',data.csskfields.main)
    }
    this.el = document.querySelector('main')
    

    this.DOM = {
      el:this.el,
      projs:this.el.querySelectorAll('.proj'),
      medias:this.el.querySelectorAll('.md')
    }

    


    // QUE EMPIECE CON POP PARA QUE EL SCROLL NO JODA
    this.isPop = 1
    // AQUÍ HACER SI HAY ALGUNO SELECCIONADO PARA EMPEZAR
    this.lgt = this.DOM.projs.length - 1
    
    this.projid = -1
    this.projpos = -1

    //MD ( MEDIA ) POS CONTROLA LA POSICIÓN DEL SLIDER
    //-2 para cuando vaya a empezar
    //-1 Para irse
    // Resto posiciones
    
    this.mdpos = -2
    this.isMed = 1


      this.paginateFn()

    // await this.loadImages()
    // await this.loadVideos()
    


    await this.createComps()
    await this.createIos()
    

    await this.getReady()
  }
  iOpage(animobj){
    
    
    return animobj
  }

  
  paginateFn(){
    this.pags = []
    for(let [i,a] of this.DOM.medias.entries()){
      this.DOM.projs[i].querySelector('.proj_num_tot').innerHTML = a.querySelectorAll('.md_el').length
      
      this.pags.push(0)

    }

  }
  async createComps(){
    
    this.prevFn = ()=>{
      if(this.isPop == 1 || this.isMed == 1 || this.projpos == 0){
        return false
      }
      // console.log(' pa tra ')
      this.isPop = 1

      this.searchId(this.DOM.projs[this.projpos-1].dataset.id)

    }
    this.nextFn = ()=>{
      // console.log('next '+this.isPop+' '+this.isMed+' '+this.lgt)
      if(this.isPop == 1 || this.isMed == 1 || this.projpos == this.lgt){
        return false
      }
      // console.log(' pa lante ')
      this.isPop = 1

      
      this.searchId(this.DOM.projs[this.projpos+1].dataset.id)

    }
    
    
   


    await super.createComps()
    if(this.DOM.el.querySelector('.gallery_main')){
      this.components.intro = new Intro(this.DOM.el.querySelector('.gallery_main'),this.main.device)
    
    }


    if(this.main.isTouch == 0){
      this.observer = Observer.create({
        target: this.DOM.el, // can be any element (selector text is fine)
        type: "wheel,touch", // comma-delimited list of what to listen for ("wheel,touch,scroll,pointer")
        onUp: () => this.prevFn(),
        onDown: () => this.nextFn(),
        tolerance: 154,
        preventDefault: true
      })
    }


  }

  async searchId(id){
    let first = 1
    // if(this.projpos != -1){
    if(this.DOM.el.querySelector('.proj.act')){
      first = 0
      
    }
    let check = null
    for(let [i,a] of this.DOM.projs.entries()){
      // console.log(a.dataset.id+' '+id)
      if(a.dataset.id == id){
        // console.log('bingo y first'+first)
        check = i
        break
      
      }



    }

    if(check == null){
      check = 0
    }
    if(this.main.device < 2){
      if(first != 1){
        this.controlSlide(-1)
        this.projpos = check
        this.projid = this.DOM.projs[this.projpos].dataset.id
        await this.timeout(400)
        await this.animEl(check)
      
      }
      else{
        
        this.projpos = check
        this.projid = this.DOM.projs[this.projpos].dataset.id
        
        this.DOM.el.querySelector('.cnt_desc').classList.add('goOut')
        await this.animEl(check)


        this.DOM.el.querySelector('.mouseCtr').classList.add('mact')
      }

      this.projpos = check
      //CONTROLAMOS EL ID DEL PROYECTO POR SI CAMBIA DE PROYECTO, QUE SE PAREN LAS CARGAS DEL SCALELOADS
      this.projid = this.DOM.projs[this.projpos].dataset.id


      await this.timeout(1400)
      this.isPop = 0
    }
    else{
      
      if(first != 1){

        
        // this.projpos = check
        // this.projid = this.DOM.projs[this.projpos].dataset.id
        // await this.timeout(400)
        // await this.animEl(check)
      
      }
      else{
        for(let [i,a] of this.DOM.projs.entries()){
          a.classList.add('act')
          let mds = this.DOM.medias[i].querySelectorAll('img,video')
          this.scaleLoads(mds,this.DOM.projs[i].dataset.id)


          this.controlSlide(-2,i)
        }
      }
    }
    return this.DOM.projs[check]

  }


  async animEl(pos){
    let check = 0

    // console.log('anim el '+pos)
    if(pos == -1 ){


      check = 1
      pos = this.projpos
    }
    if(this.DOM.el.querySelector('.proj.act')){
      this.DOM.el.querySelector('.proj.act').classList.remove('act')
    }
    this.DOM.projs[pos].classList.add('act')
    let mds = this.DOM.medias[pos].querySelectorAll('img,video')
    await this.scaleLoads(mds,this.DOM.projs[pos].dataset.id)
    
    if(this.main.device < 2){
      if(check == 0 ){
        this.isMed = 0
        this.controlSlide(-2)
      }
    }
    else{
      // if(!this.DOM.projs[pos].classList.contains('loaded')){
      //   if(check == 0 ){
      //     this.isMed = 0
      //     this.controlSlide(-2)
      //   }
      // }
    }
    this.DOM.projs[pos].classList.add('loaded')
  }


  async scaleLoads(elswait,id){

    for(let path of elswait){
      if(this.main.device < 2){
        if(id != this.projid){
          return false
        }
      }
      if(path.tagName=='IMG'){
        if(path.dataset.lazy){

          path.dataset.src = path.dataset.lazy
          delete path.dataset.lazy

          await this.loadImage(path)
  
        }
      }
      else if(path.tagName=='VIDEO'){
        if(path.dataset.lazy){
          
          path.dataset.src = path.dataset.lazy
          delete path.dataset.lazy

          await this.loadVideo(path)
          path.classList.add('Ldd')
        }
      }
    }
    
  }


  async controlSlide(pos,i=null){
    if(this.main.device < 2){
      if(this.isMed == 1){

        return false
      }
    }
    this.isMed = 1

    let time = 0

    let lpt = '--cornerleft'
    let rpt = '--cornerright'
    let prev = this.mdpos
    if(i == null){
      i = this.projpos
      prev = this.mdpos
    }
    else{
      prev = this.pags[i]
    }
    const slides = this.DOM.medias[i].querySelectorAll('.md_el')
    const pagnum = this.DOM.projs[i].querySelector('.proj_num_act')
    // DESDE LA IZQ
    // gsap.set(slides[0],{[lpt+'0']:0+'%',[lpt+'1']:0+'%'})
    // gsap.fromTo(slides[0],{[rpt+'0']:0+'%',[rpt+'1']:0+'%'},{[rpt+'0']:100+'%',[rpt+'1']:100+'%',duration:1,ease:'power4.inOut'})
  
    // DESDE LA DERECHA
    // gsap.set(slides[0],{[rpt+'0']:100+'%',[rpt+'1']:100+'%'})
    // gsap.fromTo(slides[0],{[lpt+'0']:100+'%',[lpt+'1']:100+'%'},{[lpt+'0']:0+'%',[lpt+'1']:0+'%',duration:.6,ease:'power2.inOut'})
    
    if(pos == -2){
      time = 1600
      // DESDE LA IZQ
      pos = 0
      gsap.set(slides[pos],{[lpt+'0']:0+'%',[lpt+'1']:0+'%'})
      gsap.fromTo(slides[pos],{[rpt+'0']:0+'%',[rpt+'1']:0+'%'},{[rpt+'0']:100+'%',[rpt+'1']:100+'%',duration:1.6,ease:'power4.inOut'})
      gsap.fromTo(slides[pos].querySelector('.md_el_o'),{xPercent:-50},{xPercent:0,duration:1.2,ease:'power2.inOut'})
      

      if(slides[pos].querySelector('video')){
        // console.log('tiene')
        // console.log(slides[pos].querySelector('video'))
        slides[pos].querySelector('video').play()
      }
      pagnum.innerHTML=1
      this.mdpos = 0
    }
    else if(pos == -1){

        
      gsap.set(slides[prev],{[rpt+'0']:100+'%',[rpt+'1']:100+'%'})
      gsap.to(slides[prev],{[lpt+'0']:100+'%',[lpt+'1']:100+'%',duration:1,ease:'power4.inOut'})
      gsap.to(slides[prev].querySelector('.md_el_o'),{xPercent:22,duration:1.2,ease:'power2.inOut'})
      

      if(slides[prev].querySelector('video')){
        slides[prev].querySelector('video').pause()
      }

    }
    else{
      time = 800
      if(prev > pos){

        // console.log(' slide pa tras')

        

        gsap.set(slides[prev],{[lpt+'0']:0+'%',[lpt+'1']:0+'%'})
        gsap.to(slides[prev],{[rpt+'0']:0+'%',[rpt+'1']:0+'%',duration:1,ease:'power4.inOut'})
        gsap.to(slides[prev].querySelector('.md_el_o'),{xPercent:-22,duration:1.2,ease:'power2.inOut'})


        gsap.set(slides[pos],{[rpt+'0']:100+'%',[rpt+'1']:100+'%'})
        gsap.fromTo(slides[pos],{[lpt+'0']:100+'%',[lpt+'1']:100+'%'},{[lpt+'0']:0+'%',[lpt+'1']:0+'%',duration:1,ease:'power4.inOut'})
        gsap.fromTo(slides[pos].querySelector('.md_el_o'),{xPercent:22},{xPercent:0,duration:1.2,ease:'power2.inOut'})

      }
      else if(prev < pos){
        // console.log(' slide pa lante')



        gsap.set(slides[prev],{[rpt+'0']:100+'%',[rpt+'1']:100+'%'})
        gsap.to(slides[prev],{[lpt+'0']:100+'%',[lpt+'1']:100+'%',duration:1,ease:'power4.inOut'})
        gsap.to(slides[prev].querySelector('.md_el_o'),{xPercent:22,duration:1.2,ease:'power2.inOut'})
        
        
        gsap.set(slides[pos],{[lpt+'0']:0+'%',[lpt+'1']:0+'%'})
        gsap.fromTo(slides[pos],{[rpt+'0']:0+'%',[rpt+'1']:0+'%'},{[rpt+'0']:100+'%',[rpt+'1']:100+'%',duration:1.6,ease:'power4.inOut'})
        gsap.fromTo(slides[pos].querySelector('.md_el_o'),{xPercent:-22},{xPercent:0,duration:1.2,ease:'power2.inOut'})
        
      }

      if(slides[pos].querySelector('video')){
        slides[pos].querySelector('video').play()
      }
      if(slides[prev]){
        if(slides[prev].querySelector('video')){
          slides[prev].querySelector('video').pause()
        }
      }

      pagnum.innerHTML=pos+1

    }
   

    await window.timeout(time)
    this.isMed = 0
    return true
  }

  async animIntro(val){

    
    if(this.components.intro){
      this.components.intro.start()
    }

    let time = 300

    
    
    await Promise.all([
      window.timeout(time),
    ]).then(async()=>{
      if(this.main.device < 2){
        this.isMed = 0
      }
      else{
        this.searchId(this.DOM.projs[0].dataset.id)
      }
      
      this.isPop = 0
      if(this.main.isTouch == 0){

        this.mouseMode()
      
      }
      else{
        this.touchMode()
      }
      if(this.main.device < 2){
        for(let [i,a] of this.DOM.projs.entries()){
          a.onclick = ()=>{
            if(this.isPop == 1 || this.isMed == 1){
              return false
            }
            this.isPop = 1
            this.searchId(this.DOM.projs[i].dataset.id)
            
          }
        }
      }
    })




    



    return val
   
  }
  async touchMode(){


    if(this.main.device < 2){
      
      this.DOM.md = this.DOM.el.querySelector('.cnt_mds')
      
      this.observer = Observer.create({
        target: this.DOM.md, // can be any element (selector text is fine)
        type: "touch", // comma-delimited list of what to listen for ("wheel,touch,scroll,pointer")
        
        onClick: (e) => {
          if(this.isMed == 1){
            return false
          }
          let pos = this.DOM.md.getBoundingClientRect()

          let init = e.x - pos.x
          if(init > pos.width * .5){

            if(this.mdpos + 1 == this.DOM.medias[this.projpos].querySelectorAll('.md_el').length){
              return false
            }
            this.controlSlide(this.mdpos+1)
            this.mdpos++

          }
          else{
            if(this.mdpos < 1){
              return false
            }
            this.controlSlide(this.mdpos-1)
            this.mdpos--
          }


          

        },
      })
    }
    else{
      for(let [i,a] of this.DOM.medias.entries()){
        a.onclick = (e) =>{
          if(this.isMed == 1){
            return false
          }

          let pos = a.getBoundingClientRect()

          let init = e.x - pos.x
          if(init > pos.width * .5){

            if(this.pags[i] + 1 == this.DOM.medias[i].querySelectorAll('.md_el').length){
              return false
            }
            this.controlSlide(this.pags[i]+1,i)
            this.pags[i] = this.pags[i]+1

          }
          else{
            if(this.pags[i] < 1){
              return false
            }
            this.controlSlide(this.pags[i]-1,i)
            this.pags[i] = this.pags[i]-1
          }

          if(this.pags[i] == 0){
            a.querySelector('.btL').classList.add('inac')
          }
          else{
            a.querySelector('.btL').classList.remove('inac')

          }

          if(this.pags[i] == this.DOM.medias[i].querySelectorAll('.md_el').length-1){
            a.querySelector('.btR').classList.add('inac')
          }
          else{
            a.querySelector('.btR').classList.remove('inac')

          }

        }
        // this.controlSlide(this.mdpos-1,i)

      }
    }
    
  }
  async mouseMode(){
    // x,y,direction
    //direction 0 out, -1 LEFT, -2 Right
    let svg = this.DOM.el.querySelector('.mouseCtr svg')
    this.mouse = [0,0,0,0]
    this.triangle = {
      x0:6,
      y0:8,
      x1:6,
      y1:8,
      x2:6,
      y2:8,
      el:svg.querySelector('polygon')
    }

    this.mouseX = gsap.quickTo(svg.parentNode, "x", {duration: .6, ease: "power2"})
    this.mouseY = gsap.quickTo(svg.parentNode, "y", {duration: .6, ease: "power2"})


    this.mouseX(this.main.screen.w*.5)
    this.mouseY(this.main.screen.h*.5)
    this.triangleFn = () =>{
      this.triangle.el.setAttribute('points',this.triangle.x0+' '+this.triangle.y0+','+this.triangle.x1+' '+this.triangle.y1+','+this.triangle.x2+' '+this.triangle.y2)
        
    }

    this.DOM.el.querySelector('.toLeft').onmouseenter = (e) =>{
      this.mouse[2] = -1
      // gsap.to(this.triangle,{
      //   x0:12,y0:0,
      //   x1:0,y1:8,
      //   x2:12,y2:16,
      //   onUpdate:()=>{
      //     this.triangleFn()
      //   }
      // })

      this.DOM.el.querySelector('.mouseCtr').classList.remove('showm-r')
    }



    this.DOM.el.querySelector('.toRight').onmouseenter = (e) =>{
      this.mouse[2] = 1
      // gsap.to(this.triangle,{
      //   x0:0,y0:0,
      //   x1:12,y1:8,
      //   x2:0,y2:16,
      //   onUpdate:()=>{
      //     this.triangleFn()
      //   }
      // })


      this.DOM.el.querySelector('.mouseCtr').classList.add('showm-r')
    }

    this.DOM.el.querySelector('.mouseCtr').onmouseenter = (e) =>{
      this.DOM.el.querySelector('.mouseCtr').classList.add('showm')

    }

    this.DOM.el.querySelector('.mouseCtr').onmouseleave = (e) =>{
      // console.log('leave mouse')
      this.mouse[2] = 0

      this.DOM.el.querySelector('.mouseCtr').classList.remove('showm')
      // gsap.to(this.triangle,{
      //   x0:6,y0:8,
      //   x2:6,y2:8,
      //   onUpdate:()=>{
      //     this.triangleFn()
      //   }
      // })
    }

    this.DOM.el.querySelector('.mouseCtr').onmousemove = (e) =>{
      
      
      this.mouse[0] = e.layerX
      this.mouse[1] = e.layerY
      
    }



    this.DOM.el.querySelector('.toRight').onclick = (e) =>{
      if(this.mdpos + 1 == this.DOM.medias[this.projpos].querySelectorAll('.md_el').length){
        return false
      }
      this.controlSlide(this.mdpos+1)
      this.mdpos++
    }

    this.DOM.el.querySelector('.toLeft').onclick = (e) =>{
      if(this.mdpos < 1){
        return false
      }
      this.controlSlide(this.mdpos-1)
      this.mdpos--
    }



  }
  
  async animOut(){
    await gsap.to(this.DOM.el,{opacity:0,duration:.45,ease:'power2.inOut'})

  }
  update(speed,posy){
    super.update(speed,posy)
    if(this.mouse){

      if(this.mouse[2] != 0){

        this.mouseX(this.mouse[0])
        this.mouseY(this.mouse[1])
      }

    }
  }

}




export default Home